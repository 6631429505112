.burger-btn {
    width: 24px;
    height: 24px;
    position: absolute;
    cursor: pointer;
    display: none;
    z-index: 99999;
    right:20px;
    margin-top: 5px;
    @media screen and (max-width:767px){
        display: block;
    }

}
.displayNav{
    .burger-btn{
        position: fixed;
        z-index: 9;
    }
    .burger-btn:after, .burger-btn:before {
        background-color: #fff;
    }
    .searchHeader{
        display: none;
    }
}

.burger-btn:after, .burger-btn:before {
    content: "";
    width: 16px;
    left: 5px;
    height: 2px;
    background-color: #1591AB;
    position: absolute;
    display: block;
    border-radius: 3px;
    transition: all .3s;
}
.burger-btn:before {
    top: 10px;
}
.burger-btn:after {
    top: 15px;
}
// .burger-btn.opened {
//     position: fixed;
// }
.burger-btn.opened:before {
    transform: rotate(45deg);
    top: 12px;
}
.burger-btn.opened:after {
    transform: rotate(-45deg);
    top: 12px;
}