@import "../../../assets/scss/common_files";

.searchInputText {
  @extend %light;
  font-size: 24px;
  width: 100%;
  background: none;
  border: 1px solid #d6d6d6;
  border-width: 0 0 1px;
  padding: 10px 0;

  &:focus {
    outline: none;
  }
}

.searchInputTextEnglish {
  @extend %light;
  font-size: 24px;
  width: 100%;
  background: none;
  border: 1px solid #d6d6d6;
  border-width: 0 0 1px;
  padding: 10px 0;
  text-align: right;
  direction: ltr;

  &:focus {
    outline: none;
  }
}

.searchBox {
  margin: 30px 0;
}

.searchBoxFocus {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  margin: 0;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.918);
  padding-top: 116px;
  z-index: 9;
}

.searchContainer,
.searchResultSuggestion {
  width: 100%;
  max-width: 716px;
  direction: rtl;
  margin: 0 auto;
  box-sizing: border-box;

  @media screen and (max-width: 730px) {
    max-width: 414px;
  }

  @media screen and (max-width: 420px) {
    // margin: 0 20px;
    width: 90%;
  }
}

.searchContainer {
  //padding-top: 0px !important;
  padding: 40px 30px;
}

.searchResultSuggestion {
  padding: 20px 30px 40px;
}

.searchContainerFocus {
  position: relative;
  direction: rtl;

  .close {
    width: 45px;
    height: 25px;
    background: url(../../../assets/img/close.svg) no-repeat 50% 50%;
    background-size: contain;
    position: absolute;
    left: 20px;
    top: 20px;
    cursor: pointer;
  }
}

.searchContainerFocus {
  background-color: #f8f8f8;
  border-radius: 36px;
}

.hint {
  @extend %light;
  font-size: 16px;
  color: #a2a2a2;
  padding-top: 8px;
}

.autoCompleteSuggestionItem {
  @extend %medium;
  font-size: 14px;
  padding: 6px 0;
  display: inline-block;
}

.autoCompleteSuggestionItem em {
  color: #1691ab
}

.searchResultTitle {
  font-size: 18px;
  margin-bottom: 6px;
  margin-top: 6px;
}

.searchAutoCompleteContainer {
  display: none;
}

.searchBoxFocus .searchAutoCompleteContainer {
  display: block;
}

.searchContainer {
  position: relative;

  &.searchContainerFocus {
    .searchIcon {
      display: none;
    }
  }

  .searchIcon {
    font-size: 25px;
    position: absolute;
    left: 30px;
    top: 50px;
    cursor: pointer;
  }
}