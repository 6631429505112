@import './mixins';


// @font-face {
//   font-family: 'Bold';
//   src: url("../fonts/ar/Bold/Bold.eot"); /* IE9 Compat Modes */
//   src: url("../fonts/ar/Bold/Bold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
//   url("../fonts/ar/Bold/Bold.woff2") format("woff2"), /* Modern Browsers */
//   url("../fonts/ar/Bold/Bold.woff") format("woff"), /* Modern Browsers */
//   url("../fonts/ar/Bold/Bold.ttf") format("truetype"), /* Safari, Android, iOS */
//   url("../fonts/ar/Bold/Bold.svg#Bold") format("svg"); /* Legacy iOS */
    
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Medium';
//   src: url("../fonts/ar/Medium/Medium.eot"); /* IE9 Compat Modes */
//   src: url("../fonts/ar/Medium/Medium.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
//   url("../fonts/ar/Medium/Medium.woff2") format("woff2"), /* Modern Browsers */
//   url("../fonts/ar/Medium/Medium.woff") format("woff"), /* Modern Browsers */
//   url("../fonts/ar/Medium/Medium.ttf") format("truetype"), /* Safari, Android, iOS */
//   url("../fonts/ar/Medium/Medium.svg#Medium") format("svg"); /* Legacy iOS */
    
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Light';
//   src: url('../fonts/ar/Light/Light.eot');
//     src: url('../fonts/ar/Light/Light.eot?#iefix') format('embedded-opentype'),
//          url('../fonts/ar/Light/Light.woff2') format('woff2'),
//          url('../fonts/ar/Light/Light.woff') format('woff'),
//          url('../fonts/ar/Light/Light.ttf') format('truetype'),
//          url('../fonts/ar/Light/Light.svg#Light') format('svg');
  
//   font-weight: normal;
//   font-style: normal;
// }



%bold {
  font-family: 'Bold';
}
%medium {
  font-family: 'Medium';
 
}
%light {
  font-family: 'Light';
}

