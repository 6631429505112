@import "../../../assets/scss/common_files";

.articleBlockImgWrapperSmall {
  display: block;
  float: right;
  width: 90px;
  height: 90px;
  overflow: hidden;
  border-radius: 0px;
}
.articleBlockImgSmall {
  display: block;
  width: 100%;
  border-radius: 18px;
  overflow: hidden;
}

.articleBlockContentWrapperSmall {
  display: block;
  margin-right: 100px;
}
.articleTitleSmall {
  @extend %medium;
  display: block;
  font-size: 18px;
  //margin-bottom: 25px;
  margin-bottom: 0px;
  text-align: end !important;
  direction: ltr;
}

.articleBlockSmall {
  @extend %clearfix;

  margin-bottom: 20px;
}

// .articleBlockSmall .title {
//   font-size: 18px;
//   margin-bottom: 15px;
// }

.articleInfo {
  @extend %clearfix;
  @extend %medium;
  font-size: 14px;
  margin-bottom: 15px;
  color: #666666;
  font-family: "Medium" !important;
}

.articleWrite {
  float: right;
  color: #a2a2a2 !important;
  font-size: 12px !important;
}

.articlePublishedDate {
  //float: left;
  font-size: 12px !important;
  float: right !important;
  padding-right: 10px !important;
}

.articleBlockSmall {
  .articleBlockImgWrapperSmall {
    width: 150px;
    height: 90px;
    margin-left: 20px;

    .articleBlockImg {
      width: 150px;
      height: 90px;
      object-fit: cover;
    }
  }

  .articleTitleSmall {
    display: flex;
    margin-bottom: 5px;
    font-size: 16px;
    justify-content: flex-start;
    align-items: flex-start;
    direction: rtl;
    text-align: right !important;
  }
}
