@import "../../../assets/scss/common_files";
.btn {
  // @extend %regular;
  border-radius: 30px;
  border: none;
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  line-height: 1.2;
  font-size: 14px;
  padding: 15px 20px;
  min-width: 110px;

  &:focus {
    outline: none;
  }
  &[disabled] {
    background: $BtnDisabledBG;
    color: $BtnDisabledTxt;
  }
}
.btn-primary,
a.btn-primary {
  color: $BtnPrimaryTextColor;
  background: $BtnPrimaryTextBg;
}

.gray-button {
  border-radius: 1px;
  border: 0.5px solid #a2a2a29c;
  color: #666 !important;
  background: #fff;
  &:focus {
    border-radius: 1px;
    border: 0.5px solid #f8cf6d;
    color: #fff !important;
    background: #f8cf6d;
  }
}
.donationValue-button {
  border-radius: 1px;
  border: 0.5px solid #a2a2a29c;
  color: #666 !important;
  background: #fff;
  &:focus {
    border-radius: 1px;
    border: 0.5px solid #ffcd59;
    color: #fff !important;
    background: #ffcd59;
  }
}

.orange-button {
  border-radius: 1px;
  border: 0.5px solid #ffcd59;
  color: #fff !important;
  background: #ffcd59;
}

.downloadTemplate {
  border-radius: 1px;
  border: 1px solid #ffcd59;
  color: #666 !important;
  background: #fff;
  padding: 15px;
  width: auto;
  height: auto;
  margin: 10px 0 10px 10px;
  font-size: 14px;
  font-family: "Medium";
}

.btn-main,
a.btn-main {
  color: $BtnMainTextColor !important;
  background: $BtnMainTextBg;
  font-size: 20px;
  padding: 10px 20px;
}

.btn-grey {
  color: $BtnGreyTextColor;
  background: $BtnGreyTextBg;
}

.hint .btn-grey {
  color: #524c4c;
  font-size: 12px;
  font-weight: bold;
  text-decoration: underline;
  background: #ffffff;
}
.btn-download {
  border-radius: 18px;
  border: 2px solid #1591ab;
  color: #1591ab !important;
  background: #fff;
  padding: 15px;
}

.btn-secondary,
a.btn-secondary {
  color: $BtnSecondaryTextColor;
  background: $BtnSecondaryTextBg;
  border-color: $BtnSecondaryBorderColor;
}

.blue-button {
  border-radius: 1px;
  border: 1px solid #1591ab;
  padding: 1px;
  width: 90px;
  height: 30px;
  font-size: 14px;
  font-family: "Medium";
  background: #1591ab;
  color: white !important;
  min-width: 50px;
}
