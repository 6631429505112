
$BaseTextColor: #303030;
$BaseLinkColor: #303030;


// buttons
$BtnPrimaryTextColor: #fff;
$BtnPrimaryTextBg: #1591AB;

$BtnDisabledBG: #eee;
$BtnDisabledTxt: #999;

$BtnSecondaryTextColor: #1591AB;
$BtnSecondaryTextBg: #fff;
$BtnSecondaryBorderColor: #1591AB;


$BtnMainTextColor: #fff;
$BtnMainTextBg: #f8cf6d;

$BtnGreyTextColor: #303030;
$BtnGreyTextBg: #F8F8F8;

//Error
$ErrorColor: #ff0000;
/////////////////////////




$DarkTextBg: #fff;
$DarkBGStrokeColor: #fff;



$ServerErrorBgColor: #ff3182;
$ServerErrorTxtColor: #fff;

$FooterSeparator: #4cc1f1;

$LightLinkColor: #fff;


$DarkDotColor: $DarkTextBg;
$DarkActiveDotColor: $DarkTextBg;

$SlideDetailsBg: #d7d7d7;

$placeholderColor: #b9b9b9;
$PlaceholderBG: #fff;

$ModalBg: #fff;

$SubNavBg: #fff;

$FilterNavHoverBg: #ecf6ff;

// $StoresListBg: #ecf6ff;
$StoreBubleBg: #fff;
$StoreNameColor: #00A6EB;

$TabsBorderColor:#00A6EB;
$TabBg: #fff;
$TabColor: #00A6EB;

$TabBgActive: #00A6EB;
$TabColorActive: #fff;

////////////////////////////////
$HeaderBg: #fff;
$LinkColor: #00aeef;
$HeaderLinkColorActive: #1a1f45;
$DarkLinkColor: #65656a;
$LinkColored: #d1005d;
$inputBorder: #bdbdbd;
$inputBorderFocus: #00aeef;

$errorMessagesTextColor: #ff4949;
$errorMessagesBorderColor: #ff4949;



$BtnOutlineTextColor: #00A6EB;
$BtnOutlineTextBg: #fff;
$BtnOutlineBorderColor: #00A6EB;

$SocialBtnBorder: #dedede;

$SupportSectionBorder: #dbdbdb;

// $HeaderBg: rgba(255, 255, 255, 0.5);

$FooterTopBg: #d0e9ff;
$FooterBottomBg: #00aeef;

$FooterLinkColor: #fff;
$Copywrite: #fff;
$SocialLinkBorderColor: #fff;
$DefaultGradiant: linear-gradient(109.24deg, #00a6eb 4.36%, #81dafc 100.48%);

$txt16: 0.833333vw;

$burgerLinesColor: #00aeef;

$LangHeaderBG: linear-gradient(94.44deg, #00a6eb 0%, #75d5fb 100%);
$LangHeaderTxtColor: #fff;

$CopywriteLink: #fff;

$UpperSectionTxtColor: #fff;

$ContactSocialBoxTxtColor: #65656a;
$ContactSocialBoxTxtActiveColor: #00aeef;

$CareerBoxTxtColor: #00aeef;
$CareerBoxBGColor: #ffffff;

$formTitleTxtColor: #65656a;
$formTxtColor: #65656a;

$upperSectionTxtColor: #fff;

$aboutUsTxtColor: #ffffff;

$jobDescriptionBgColor: #ecf6ff;
$jobTitleBgColor: #00aeef;
$CarouselDescTxtColor: #65656a;

$UpperSectionIntroTitleTxtColor: #fff;

$SectionNavItemBorderColor: #00aeef;
$SectionNavLnk: #a2a2a2;
$SectionNavLnkActive: #fff;
$SectionNavLnkActiveBg: #00aeef;

$GradiantBg: linear-gradient(98.74deg, #00a6eb 4.36%, #81dafc 100.48%);
$GradiantBgTitleColor: #fff;
$DownloadTitleColor: #00aeef;

$LightBg: #ecf6ff;

$FaqTitleLnk: #65656a;
$FaqItemBorderColor: #dadada;
$FaqLnkColor: #65656a;
$ActivateHeaderColor: #00aeef;
$InBetweenResultItemBorder: #eee;

$tableHeaderColor: #00aeef;
$tableColoredRowColor: #ecf6ff;

$localsContainerBgColor: #ecf6ff;
$offersBoxBgColor: #fff;
$offersBoxHeaderBgColor: rgba(159, 159, 159, 0.1);
$offersSubTitleTextColor: #9f9f9f;
$offersHeaderBorderColor: rgba(159, 159, 159, 0.2);
$offersSubHeaderColor: #00aeef;
$offersShareBtnTxtColor: #d1005d;
$bestSellerBoxTxtColor: #fff;
$bestSellerBoxBgColor:  #01AABD;

$newBundleBoxTxtColor: #fff;
$newBundleBoxBgColor:  #FFA028;

$destinationBoxBgColor: #ffffff;
$destinationBoxTxtColor: #ffffff;
$destinationGradientBulletColor: linear-gradient(
  100.26deg,
  #d1005d 16.78%,
  #ff89bd 100%
);
$SliderBtnsHover: linear-gradient(100.26deg, #d1005d 16.78%, #ff89bd 100%);
$SliderBtnsBg: #fff;
$LoaderColorAnimation: #00aeef;

$WSCNavBg: #d0e9ff;
$WSCNavBorderColor: #fff;
$WSCNavActive: #00aeef;
$WSCNavBorderColorActive: #00aeef;
$WSCNavIconBg: #65656a;
$WSCNavIconBgActive: #00aeef;

$WSCSubNavBgActive: #d1005d;
$WSCSubNavColorActive: #fff;

$BoxTitleColor: #65656a;
$BalanceAmountColor: #00aeef;

$THColor: #00aeef;

$UsageMainColor: #00aeef;
$UsageMainColorBG: #f3f9ff;
$UsagePurchaseColor: #d1005d;

$UsageOtherColor: #d1005d;
$UsageOtherColorBG: #ffeff6;

$myAccountUserInfoValueTxtColor: #00aeef;
$myAccountFormButtonTxtColor: #00aeef;
$pinPukInputBorderColor: #dadada;
$pinPukInputTxtColor: #00a6eb;
$pinPukBorderColor: #00aeef;
$lostSimTxtColor: #d1005d;
// $BtnMainTextColor: #fff;
// $BtnMainTextBg: linear-gradient(126.26deg, #00a6eb 16.78%, #9ae4ff 100%);

$VanityNumbersTitleTxtColor: #00aeef;
$PhoneNumberTxtColor: #00aeef;
$PhoneNumberInputBorderColor: #b1b1b1;
$PhoneNumberHintColor: #b1b1b1;
$SecondaryButtonTxtColor: #b1b1b1;

$PickNumberBgBoxColor: #ccc;
$PickNumberTxtColor: #fff;


$priceOfNumberTxtColor: #000;

$popupHeaderTxtColor: #00aeef;
$popUpContentTitleTxtColor: #65656a;
$popUpStepsBackground: #f9f9f9;
$popUpStepsTxtColor: #fff;
$popUpActiveStepBgColor: #d1005d;
$popUpInBetweenInActiveStepsBgColor: #dbdbdb;
$popUpInBetweenActiveStepsBgColor: #d1005d;
$phoneNumberTxtColor: #00aeef;
$popUpAnchorTxtColor: #d1005d;
$popUpPharagraphTxtColor: #a8a8a8;

$orderSimPlanTitleTxtColor: #00aeef;
$orderSimActivePlanTitleTxtColor: #ffffff;
$orderSimActivePlanBgColor: linear-gradient(
  109.24deg,
  #00a6eb 4.36%,
  #81dafc 100.48%
);

$orderSimHeaderBorderBgColor: #f4f4f4;
$orderSimTableTitleTxtColor: #a8a8a8;
$orderSimPackageValueTxtColor: #d1015d;

$onlineRechargeSelectedPackageValue: #00aeef;
$onlineRechargePackageValueTxtColor: #b9b9b9;

$stepNumberTxtColor: #00aeef;
$attentionBoxTitleTxtColor: #ffffff;
$attentionBoxTitleBgColor: #d1005d;
$attentionBoxBgColor: #606060;
$attentionBoxTxtolor: #ffffff;
$bringNumberOddCheckBoxBgColor: #f8f8f8;

$loginFormInputBorderColor: #dadada;
$loginFormSocailLinksContainerBorderColor: #b1b1b1;
$loginFormSocailLinkBorderColor: #dedede;

$forgotPasswordTitleTxtColor: #3b414b;
$forgotPasswordPhoneNumberTxtColor: #00aeef;

$shopBoxTitleTxtColor: #00aeef;
$shopBoxSearchBoxBgColor: #f7f7f7;

$shopItemTitleTxtColor: #65656a;
$shopPriceRangeInputBgColor: #f7f7f7;
$shopFilterTxtColor: #d1005d;

$productPriceTxtColor: #00aeef;
$RedProductBgColor: #b2001f;
$WhiteProductBgColor: #fefefe;
$WhiteProductBorderColor: #65656a;
$YellowProductBgColor: #fbd55f;
$BlueProductBgColor: #31a9ed;
$BlackProductBgColor: #2f2f30;
$SalmonProductBgColor: #fc695c;
$selectedOptionBorderColor: #00aeef;
$specificationTitleTxtColor: #00aeef;

$signUpTitleTxtColor: #3b414b;
$directLoginTabBorderColor: #dbdbdb;
$directLoginActiveTabBorderColor: #00a6eb;

$activateEmailConfirmationMsgTxtColor: #3b414b;

$floatingBoxBorderBgColor: #65656a;
$floatingBoxCloseBgColor: #00a6eb;
$floatingBoxCloseBorderColor: #fff;
$floatingBoxBgColor: #fff;

$headerTxtColor: #1a1f45;
$tableHeaderColor: #ffffff;
$tableHeaderBgColor: #64b4ff;
$tableInBetweenBorderColor: #9f9f9f;
$tableSpanNoteTxtColor: #9f9f9f;

$legalsHeaderTxtColor : #00A6EB;

$supportHeaderTxtColor : #00A6EB;
$relatedPostsHeaderTxtColor : #00A6EB;

// promoDiscountLabel
$PromoDiscountLabelColor:#01aabd;

// Stores
$SearchBoxInput:#9f9f9f;



//
$DataDenominationPriceColor: #b91866;
// Dashboard
$NoSubscriptionPlans:rgba(159, 159, 159, 0.7);
$DashboardWrapperBg:#F8F9F9;
// usage bar colors
$UsageLow: #01AABD;
$UsageMid:#FFBE28;
$UsageHigh:#FF3182;
