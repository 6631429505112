@import "../../../assets/scss/common_files";

html {
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

body {
  @extend %bold;
  line-height: 1.2;
  // color: $BaseTextColor;
}

a:link,
a:visited,
a:hover {
  text-decoration: none;
  color: $BaseLinkColor;
}
.title {
  @extend %medium;
  font-size: 24px;
  margin: 0 0 50px;
  @media screen and (max-width: 420px) {
    font-size: 24px;
  }
  @media screen and (max-width: 375px) {
    font-size: 20px;
  }
}

.searchHeader {
  width: 21px;
  height: 21px;
  float: left;
  background: url(../../../assets/img/searchIcn.png) no-repeat;
  background-size: contain;
  overflow: hidden;
  text-indent: -999px;
  cursor: pointer;
  margin: 18px 0 0 20px;
  // display: none;

  @media screen and (max-width: 767px) {
    margin: 13px 0 0 0;
  }
}
/////////////////////////////////////
.header {
  @extend %clearfix;
  padding-top: 35px;
  margin-bottom: 65px;

  @media screen and (max-width: 767px) {
    padding-top: 25px;
    margin-bottom: 25px;
  }
}
.displayNav {
  .logo {
    background-image: url(../../../assets/img/meemlogoW.svg);
  }
}
.logo {
  background-image: url(../../../assets/img/meemlogoB.svg);
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  float: right;
  width: 34px;
  height: 51px;
  overflow: hidden;
  text-indent: -999px;
  margin-left: 40px;
  @media screen and (max-width: 940px) {
    margin-left: 20px;
  }
}

.displayNav {
  .logo {
    position: fixed;
    z-index: 9;
  }
}
.headerContainer {
  direction: rtl;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  box-sizing: border-box;
  padding-left: 60px;
  padding-right: 60px;

  // @media screen and (max-width:1316px){
  //     max-width: 1200px;
  //     padding-left: 20px;
  //     padding-right: 20px;
  // }
  @media screen and (max-width: 767px) {
    padding-right: 55px;
    padding-left: 20px;
  }
}
.container,
.containerWrapper {
  direction: rtl;
  width: 100%;
  max-width: 1094px;
  margin: 0 auto;
  @media screen and (max-width: 1094px) {
    max-width: 900px;
  }
  @media screen and (max-width: 900px) {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
}
.containerWrapper {
  @extend %clearfix;
}

.displayNav {
  overflow: hidden;
}

.topHeader {
  float: left;
  margin-top: 5px;
  @media screen and (max-width: 767px) {
    float: none;
    display: none;
  }
}

.displayNav {
  .topHeader {
    display: block;
  }
}
.joinCommunity {
  @extend %medium;
  color: #fff;
  font-size: 18px;
  display: none;
}
.menuSection {
  @media screen and (max-width: 767px) {
    display: block;
  }
}

.overflow {
  overflow: hidden;
}

.serverError {
  background: #ffebeb;
  color: red;
  padding: 10px;
  @extend %medium;
  margin-bottom: 30px;
}

.loginPopup {
  position: absolute;
  padding: 10px;
  border-radius: 10px;
  background: #000;
  color: #fff;
  white-space: nowrap;
  top: 0;
  right: 0;
}

.loadMoreTerms {
  margin-bottom: 40px;
  .btn {
    display: block;
    width: 100%;
  }
}

.relative {
  position: relative;
}
