@import "../../../assets/scss/common_files";
.placeholder-wrapper {
  @extend %clearfix;
  position: absolute;
  top: 18px;
  pointer-events: none;
  transition: all 0.2s;
  padding: 0 10px;
  white-space: nowrap;
  right: 0;
  margin-right: 10px;
}

.placeholder-label {
  display: block;
  color: #b9b9b9;
  pointer-events: none;
  // margin-right: 30px;
  font-size: 16px;
  line-height: 1.4;
}

input:autofill,
input:autofill:hover,
input:autofill:focus,
input:autofill:active {
  background: none !important;
  background-image: none !important;
  color: inherit !important;
  -webkit-box-shadow: none !important;
  appearance: input !important;
}

.input-field-block {
  position: relative;
  margin-bottom: 30px;

  .searchInputTextEnglish {
    @extend %light;
    border-width: 0 0 1px;
    border-bottom: 1px solid #d6d6d6;
    background: none;

    width: 100%;
    padding: 18px 24px;
    box-sizing: border-box;
    font-size: 16px;

    &:focus {
      outline: none;
      border-bottom-color: #111;
    }
  }

  .searchInputText {
    @extend %light;
    border-width: 0 0 1px;
    border-bottom: 1px solid #d6d6d6;
    background: none;

    width: 100%;
    padding: 18px 24px;
    box-sizing: border-box;
    font-size: 16px;

    &:focus {
      outline: none;
      border-bottom-color: #111;
    }
  }
}

.searchInputText {
  &:focus,
  &.touched {
    & + .placeholder-wrapper {
      top: -13px;
    }
    &.error {
      & + .placeholder-wrapper {
        .placeholder-label {
          color: $ErrorColor;
        }
      }
      border-bottom: 2px solid $ErrorColor;
    }
  }
  &.error {
    & + .placeholder-wrapper {
      .placeholder-label {
        color: $ErrorColor;
      }
    }
    border-bottom: 2px solid $ErrorColor;
  }
}

.searchInputTextEnglish {
  &:focus,
  &.touched {
    & + .placeholder-wrapper {
      top: -13px;
    }
    &.error {
      & + .placeholder-wrapper {
        .placeholder-label {
          color: $ErrorColor;
        }
      }
      border-bottom: 2px solid $ErrorColor;
    }
  }
  &.error {
    & + .placeholder-wrapper {
      .placeholder-label {
        color: $ErrorColor;
      }
    }
    border-bottom: 2px solid $ErrorColor;
  }
}

.error-message {
  @extend %light;
  padding-top: 5px;
  font-size: 12px;
  color: $ErrorColor;
}
// .password-field{
//     background-image: url('../../../assets/img/eye_show.svg');
// }

// .hide-password{
//     background-image: url('../../../assets/img/eye_hide.svg');
// }

.eye {
  width: 20px;
  height: 12px;
  position: absolute;
  cursor: pointer;
  z-index: 2;
  background-image: url("../../../assets/img/eye_show.svg");
  background-size: contain;
  left: 0;
  top: 23px;
}
