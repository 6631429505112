@import '../assets/scss/common_files';

.mainSide {
    @extend %clearfix;
}

.sideColumn {
    width: 32%;
    float: left;
    box-sizing: border-box;
    padding-right: 20px;
    padding-bottom: 30px;

    @media screen and (max-width:767px) {
        float: none;
        width: auto;
        padding-right: 0;
    }
}

.mainContent {
    width: 68%;
    // max-width: 716px;
    box-sizing: border-box;
    float: right;
    padding-left: 20px;

    @media screen and (max-width:767px) {
        float: none;
        width: auto;
        padding-left: 0;
    }
}