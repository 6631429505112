@import '../../../assets/scss/common_files';
.textarea{
    border: none;
    width: 100%;
    border-bottom:1px solid #D6D6D6;
    box-sizing: border-box;
    display: block;
    padding-right: 20px;
    &:focus{
        outline: none;
        border-bottom-color: #111;
    }
}