@import "../../../assets/scss/common_files";
.modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99999;
}

.modal-wrapper {
  // @extend %regular;
  background-color: $ModalBg;
  width: 100%;
  max-width: 63%;
  margin: 0 auto;
  position: relative;
  //min-height: 300px;
  transform: translateY(100px);
  border-radius: 15px;
  padding: 18px;
  box-sizing: border-box;

  direction: rtl;

  &.withShadow {
    @media screen and (max-width: 414px) {
      width: 90%;
      height: auto;
      transform: translateY(10%);
    }
  }

  @media screen and (max-width: 414px) {
    transform: translateY(0);
    border-radius: 0;
    padding: 30px 15px;
    max-width: inherit;
    height: 100%;
  }
}

.modal-content {
  overflow-y: auto;
  max-height: 600px;
  @media screen and (max-width: 414px) {
    max-height: 100%;
  }
}

.modal-close {
  background-color: $ModalBg;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 37px;
  cursor: pointer;
  height: 37px;
  position: absolute;
  width: 37px;
  top: -18px;

  right: -18px;

  @media screen and (max-width: 414px) {
    top: 20px;
    // background-color: transparent;
    box-shadow: none;
    background-repeat: no-repeat;

    right: inherit;
    left: 15px;
  }

  &:before {
    background-image: url(../../../assets/img/close.svg);
    background-size: contain;
    background-repeat: no-repeat;
    content: "";
    display: block;
    height: 27px;
    margin: 0 auto;
    width: 27px;
    margin-top: 10px;
  }
}
